/* eslint-disable */ // :)
import React, { useState } from 'react';
import classNames from 'classnames';
import { default as SlickSlider } from 'react-slick';
import uniqueId from 'uniqid';
import Image from '../Image';
import RenderStaticHTML from '../RenderStaticHTML';

const nsBase = 'component';
const ns = `${ nsBase }-slider`;

const Slider = ({
	modifier,
	heading,
	description,
	slides,
	hTag,
	sliderHelperText,
	sliderSettings,
	showPagination,
}) => {
	const totalSlides = slides.length;
	const [currentSlide, setCurrentSlide] = useState(1);

	const HTag = hTag;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--${ modifier }`]: modifier,
	});

	if (showPagination) {
		sliderSettings = {
			...sliderSettings,
			beforeChange: (currentIndex, nextIndex) => {
				setCurrentSlide(nextIndex + 1);
			},
		};
	}

	return (
		<div className={`${ rootClassnames } container-fluid`}>
			{heading && (
				<div className={`${ ns }__heading ${ HTag }`}>
					{ heading }
				</div>
			)}
			{description && (
				<div className={`${ ns }__description`}>
					<RenderStaticHTML html={ description } />
				</div>
			)}
			{slides && totalSlides && (
				<div className={`${ ns }__slides`}>
					<SlickSlider {...sliderSettings}>
						{slides.map(slide => {
							return (
								<div key={uniqueId('violux')} className={`${ ns }__slide`}>
									<div className={`${ ns }__slide-content`}>
										{slide.image && (
											<div className={`${ ns }__slide-image-container`}>
												<Image
													{...slide.image}
												/>
											</div>
										)}
										{slide.heading && <p className={`${ ns }__slide-heading`}>{ slide.heading }</p>}
										{slide.description && (
											<div className={`${ ns }__slide-description`}>
												{showPagination && (
													<div className={`${ ns }__pagination`}>
														{currentSlide}/{totalSlides}
													</div>
												)}
												{ slide.description }
											</div>
										)}
									</div>
								</div>
							);
						})}
					</SlickSlider>
				</div>
			)}
			{sliderHelperText && (
				<div className={`${ ns }__slider-helper-text`}>
					{sliderHelperText}
				</div>
			)}
		</div>
	);
};

Slider.defaultProps = {
	hTag: 'h4',
};

export default Slider;
